import Page from '@components/Layout/Page';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

const RulesPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "live/rules.mdx" }) {
				childMdx {
					body
				}
			}
		}
	`);
	return (
		<Page title="Rules" live>
			<h1 className="font-bold my-3 text-5xl">Rules</h1>

			<MDXRenderer>{data.file.childMdx.body}</MDXRenderer>
		</Page>
	);
};

export default RulesPage;
